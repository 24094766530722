$red: #007BFF;

$primary: $red;

$grid-gutter-width: 14px;

$input-btn-padding-y-lg: 10.5px;
$input-btn-font-size-lg: 18px;

$input-bg: #F1F3F8;
$input-border-color: $input-bg;

$input-placeholder-color: #9EAABD;

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

* {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
}

@import 'node_modules/bootstrap/scss/bootstrap-grid';

@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/spinners';

@import './fonts';

@import './typo';

@import './header';
@import './footer';
@import './icons';

@import './top-panel';
@import './reviews';
@import './statistics';
@import './whythem';
@import './registration-box';

@import './carousel';

@import './popup';
@import './selectric';

body {
    font-family: 'SF Pro Display';
    font-size: 14px;
    line-height: 26px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 26px;
    }
}

.wrapper {
    overflow: hidden;
    .container {
        @media (max-width: 991.98px) {
            padding-left: 14px;
            padding-right: 14px;
        }
    }

    a {
        text-decoration: none;
    }
}

.text {
    &--normal {
        font-weight: 400 !important;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--success {
        color: #B0DB1F;
    }
}

button,
input,
select,
textarea {
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
        border-color: #F1F3F8 !important;
    }
}

.form-control {
    font-family: 'Source Sans Pro' !important;
}
