.owl-carousel {
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;

    display: none;

    width: 100%;

    -webkit-tap-highlight-color: transparent;
}
.owl-carousel .owl-stage {
    position: relative;

    -moz-backface-visibility: hidden;
    -ms-touch-action: pan-Y;
        touch-action: manipulation;
    /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
    content: '.';

    display: block;
    visibility: hidden;
    clear: both;

    height: 0;

    line-height: 0;
}
.owl-carousel .owl-stage-outer {
    position: relative;

    overflow: hidden;
    /* fix for flashing background */

    -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);

    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
}
.owl-carousel .owl-item {
    position: relative;

    float: left;

    min-height: 1px;

    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
    display: block;

    width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

     -khtml-user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    padding: 0 !important;

    font: inherit;

    color: inherit;
    border: none;
    background: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
    width: 22px;
    height: 40px;
    padding: 0 !important;

    font-size: 0 !important;
    line-height: 40px;

    color: $white !important;
    border: none;
    border-radius: 50%;
}
.owl-carousel .owl-nav {
    position: absolute;
    top: 170px;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 40px;
}
.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 0;
    left: 5px;

    background: url('../img/arrow-left.svg') no-repeat 50% 50%;
}
.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: 0;
    right: 5px;

    background: url('../img/arrow-right.svg') no-repeat 50% 50%;
}

.owl-carousel .owl-dots {
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: center;

    margin-bottom: 88px;

    @include media-breakpoint-up(xl) {
        margin-bottom: 120px;
    }

    & .owl-dot {
        width: 12px;
        height: 12px;
        margin: 0 3.5px;

        border: none;
        border-radius: 50%;
        background: rgba(13, 29, 74, .25);

        &.active {
            background: rgba(13, 29, 74, 1);
        }
    }
}

.owl-carousel.owl-loaded {
    display: block;
}
.owl-carousel.owl-loading {
    display: block;

    opacity: 0;
}
.owl-carousel.owl-hidden {
    opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -ms-touch-action: pan-y;
        touch-action: pan-y;
}
.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
}
.owl-carousel.owl-rtl {
    direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
    float: right;
}

/* No Js */
.no-js .owl-carousel {
    display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
    animation-duration: 1000ms;

    animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
    z-index: 0;
}

.owl-carousel .owl-animated-out {
    z-index: 1;
}

.owl-carousel .fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
    transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
    /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}
.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=''],
.owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
    position: relative;

    height: 100%;

    background: #000;
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-left: -40px;

    cursor: pointer;

    background: url('owl.video.play.png') no-repeat;
    transition: transform 100ms ease;

    -webkit-backface-visibility: hidden;
}

.owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
        transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
}

.owl-carousel .owl-video-tn {
    height: 100%;

    opacity: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;

    width: 100%;
    height: 100%;
}
