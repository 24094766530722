.footer {
    padding-right: 10px;
    padding-left: 10px;

    font-size: 16px;
    line-height: 19px;

    color: rgba(13, 29, 74, .5);

    & p {
        margin-bottom: 20px;

        @include media-breakpoint-up(xl) {
            margin-bottom: 30px;
        }

        & strong {
            font-weight: 800;
            font-size: 18px;
            line-height: 21px;
        }
    }

    & a {
        text-decoration: none;

        color: inherit;
    }

    & img {
        display: block;

        max-width: 100%;
        margin-bottom: 20px;
    }
}
