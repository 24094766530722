.icons {
    display: inline-block;
    overflow: hidden;
    flex-shrink: 0;

    width: 1em;
    height: 1em;

    font-size: inherit;

    user-select: none;
    vertical-align: middle;

    &--phone {
        background: url('../img/icons/phone.svg') no-repeat 50% 50%;
        background-size: 100% 100%;
    }

    &--user1 {
        background: url('../img/icons/user1.svg') no-repeat 50% 50%;
        background-size: 100% 100%;
    }

    &--user2 {
        background: url('../img/icons/user2.svg') no-repeat 50% 50%;
        background-size: 100% 100%;
    }

    &--arrow {
        background: url('../img/icons/arrow.svg') no-repeat 50% 50%;
    }
}