.why-them {
    position: relative;

    padding: 90px 25px;

    color: #fff;
    background: #007bff url('../img/whythem.svg') no-repeat 50% 50%;
    background-size: cover;

    @include media-breakpoint-up(md) {
        padding-left: 86px;
        // padding-right: 86px;
    }

    &:after {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        display: none;

        width: 100%;
        height: 100%;

        background: url('../img/vector7.svg') no-repeat 0 100%;

        @include media-breakpoint-up(md) {
            display: block;
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    &:before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        display: block;

        width: 100%;
        height: 100%;

        background: url('../img/vector6.svg') no-repeat 100% 0;

        @include media-breakpoint-up(xl) {
            background-position-y: 100%;
        }
    }

    & h3 {
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
    }

    &__item {
        position: relative;
        z-index: 5;

        margin-bottom: 30px;

        font-size: 16px;
        line-height: 122%;

        @include media-breakpoint-up(md) {
            max-width: 265px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &__title {
            display: flex;
            align-items: center;

            margin-bottom: 13px;
        }

        &__num {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 38px;
            height: 38px;
            margin-right: 8px;
            box-sizing: border-box;

            font-weight: 700;
            font-size: 24px;
            line-height: 122%;

            border: 1px solid #fff;
            border-radius: 50%;
            background: #a2db00;
        }

        & h4 {
            margin-bottom: 0;

            font-weight: 700;
            font-size: 24px;
            line-height: 22px;

            letter-spacing: .1px;
        }
    }

    &__women {
        display: none;

        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: 0;
            left: -140px;
            z-index: 2;

            display: block;

            width: 704px;
            height: 722px;

            background: url('../img/people1.png') no-repeat 50% 100%;
            background-size: cover;
        }

        @include media-breakpoint-up(xl) {
            left: 50%;
            transform: translateX(-100%);
            width: 704px;
            height: 100%;
            background-size: contain;
        }
    }

    &__content {
        width: 100%;

        @include media-breakpoint-up(xl) {
            width: 580px;
            margin-left: auto;
        }
    }
}
