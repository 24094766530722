.popoup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    width: 100%;
    height: 100%;
    display: none;

    &.open {
        display: block;
    }

    &__btn-open {
        position: fixed;
        left: 32px;
        bottom: 32px;

        padding: 10px 18px 10px;

        font-weight: 700;
        font-size: 14px;
        line-height: 18px;

        cursor: pointer;
        text-align: left;

        color: #fff;
        border: none;
        border-radius: 16px;
        background: #007bff;
        z-index: 9999;
    }

    &__overly {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba(#000, .7);
    }

    &__conteiner {
        position: relative;

        width: 100%;
        max-width: 840px;
        padding: 32px 32px;
        border-radius: 32px;
        background: #fff;

        &__close {
            position: absolute;
            top: 32px;
            right: 32px;

            width: 22px;
            height: 22px;

            cursor: pointer;

            background: url('../img/close.svg') no-repeat 50% 50%;
        }
    }

    &__content {
        width: 100%;
        max-width: 482px;
        margin-right: auto;
        margin-left: auto;

        & h3 {
            margin-bottom: 21px;

            font-weight: 800;
            font-size: 34px;
            line-height: 34px;

            text-align: center;

            color: #0d1d4a;
        }
    }

    &__form {
        color: #0d1d4a;

        &__group {
            margin-bottom: 21px;

            & label {
                display: block;

                margin-bottom: 13px;

                font-weight: 700;
                font-size: 18px;
                line-height: 21px;

                color: #0d1d4a;
            }
            & label.error {
                color: red;
                font-weight: 500;
                font-size: 12px;
            }
            & small {
                font-size: 12px;
                line-height: 14px;
                display: inline-block;
                margin-top: 9px;
                margin-bottom: 9px;

                color: #818ba5;
            }
        }

        &__calc {
            margin-top: 44px;
            margin-bottom: 44px;

            &__label {
                margin-bottom: 20px;

                font-size: 18px;
                line-height: 21px;

                color: #0d1d4a;
            }

            &__price {
                margin-bottom: 10px;

                font-weight: 700;
                font-size: 28px;
                line-height: 33px;

                color: #0d1d4a;
            }

            &__sale {
                margin-bottom: 20px;

                font-size: 16px;
                line-height: 19px;

                color: #f00;
            }

            &__desc {
                font-size: 14px;
                line-height: 17px;

                color: #818ba5;
            }
        }

        &__textfield {
            width: 100%;
            padding: 21px 20px 20px;
            box-sizing: border-box;

            font-size: 18px;
            line-height: 21px;

            border: 1px solid #bbc2d1;
            border-radius: 16px;
            background: #f6f9ff;
        }

        &__btn {
            width: 100%;
            padding: 22px 18px 21px;

            font-weight: 700;
            font-size: 18px;
            line-height: 21px;

            cursor: pointer;
            text-align: center;

            color: #fff;
            border: none;
            border-radius: 16px;
            background: #007bff;
        }

        &__gift {
            height: 175px;
            display: block;
            width: 100%;
            background-size: 100% !important;
            background: url('../img/grad_gifts.gif') no-repeat 50% 50%;
        }
    }
}
