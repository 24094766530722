.statistics {
    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;
        height: 264px;
        margin-bottom: 40px;
        padding: 15px 25px 0;
        position: relative;

        color: #fff;
        border-radius: 16px;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }

        @include media-breakpoint-up(xl) {
            height: 180px;
            padding: 8px 25px 0;
            margin-bottom: 120px;
        }

        &:after {
            content: '';

            display: block;

            width: 200px;
            height: 100px;
            margin-top: auto;
            margin-left: auto;

            @include media-breakpoint-up(xl) {
                position: absolute;
                bottom: 0;
            }
        }

        &.first-of-type {
            background: #007bff url('../img/BG-2.png') no-repeat 50% 50%;
            background-size: cover;

            &:after {
                background: url('../img/people2.png') no-repeat 50% 100%;
                background-size: contain;
            }

            @include media-breakpoint-up(md) {
                &:after {
                    width: 355px;
                    height: 243px;
                }
            }

            @include media-breakpoint-up(xl) {
                &:after {
                    width: 212px;
                    height: 145px;
                    right: 0;
                    z-index: 1;
                }
            }
        }

        &.second-of-type {
            background: #b0db1f url('../img/BG-4.png') no-repeat 50% 50%;
            background-size: cover;

            @include media-breakpoint-up(sm) {
                justify-content: flex-end;
            }

            &:after {
                width: 100px;

                background: url('../img/people4.png') no-repeat 50% 100%;
                background-size: contain;

            }

            @include media-breakpoint-up(md) {
                &:after {
                    width: 139px;
                    height: 242px;
                    order: 1;
                    margin-right: auto;
                }

                & .statistics__item__content {
                    order: 2;
                }
            }

            @include media-breakpoint-up(xl) {
                &:after {
                    width: 86px;
                    height: 150px;
                    left: 30px;
                    z-index: 1;
                }
            }
        }

        &.last-of-type {
            background: #ff6269 url('../img/BG-5.png') no-repeat 50% 50%;
            background-size: cover;

            &:after {
                width: 150px;

                background: url('../img/people5.png') no-repeat 50% 100%;
                background-size: contain;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 80px;

                &:after {
                    width: 317px;
                    height: 201px;
                    margin-bottom: auto;
                    margin-right: 89px;
                }
            }

            @include media-breakpoint-up(xl) {
                &:after {
                    width: 185px;
                    height: 117px;
                    bottom: 5px;
                    right: 30px;
                    z-index: 1;
                    margin-right: 0;
                }
            }
        }

        &__content {
            max-width: 320px;

            font-size: 30px;
            line-height: 122%;
            position: relative;

            z-index: 10;

            @include media-breakpoint-up(xl) {
                font-size: 18px;
                max-width: 100%;
            }

            & span {
                display: block;

                margin-bottom: 14px;

                font-weight: 800;
                font-size: 48px;
                line-height: 122%;

                @include media-breakpoint-up(sm) {
                    font-size: 64px;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 48px;
                    margin-bottom: 0;
                }
            }
        }
    }
}
