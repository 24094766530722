.registration-box {
    padding-top: 87px;
    padding-bottom: 50px;

    @include media-breakpoint-up(xl) {
        padding-top: 120px;
    }

    & h4 {
        margin-bottom: 24px;

        font-weight: 800;
        font-size: 24px;
        line-height: 29px;

        @include media-breakpoint-up(md) {
            max-width: 450px;
        }

        & span {
            color: #007bff;
        }
    }

    &__form {
        position: relative;

        padding: 36px 19px;

        border-radius: 18px;
        background: #b0db1f;

        & .row {
            @include media-breakpoint-up(xl) {
                max-width: 545px;
            }
        }
        &-group {
            & .error-text {
                display: block;

                margin-top: -20px;
                margin-bottom: 8px;
                margin-left: 16px;

                font-size: 12px;
                line-height: 1;

                color: #ff5159;

                &.hidden {
                    display: none;
                }
            }
        }
        &:before {
            content: '';

            position: absolute;
            right: 0;
            bottom: 0;

            display: block;

            width: 263px;
            height: 218px;

            background: url('../img/people3.png') no-repeat 50% 100%;
            background-size: contain;

            @include media-breakpoint-up(xl) {
                width: 352px;
                height: 292px;
                right: 100px;
            }
        }

        &-input {
            display: block;

            width: 100%;
            margin-bottom: 20px;
            padding: 18px 18px 17px;
            box-sizing: border-box;

            font-weight: 300;
            font-size: 22px;
            line-height: 26px;

            color: rgba(13, 29, 74, .5);
            border: .5px solid  #bbc2d1;
            border-radius: 16px;
            background: #f6f9ff;
            &.error {
                border: 1px solid #ff5159;
                &:focus {
                    border: 1px solid #ff5159 !important;
                }
            }
            @include media-breakpoint-up(md) {
                max-width: 305px;
                margin-bottom: 0;
            }
            @include media-breakpoint-up(xl) {
                width: 305px;
                font-size: 18px;
                line-height: 21px;
                padding: 22px 18px 21px;
            }

            &::placeholder {
                color: #818BA5;
            }
        }

        &-btn {
            display: block;

            width: 100%;
            padding: 22px 10px 21px;

            font-weight: 700;
            font-size: 18px;
            line-height: 21px;

            text-transform: uppercase;

            color: #fff;
            border: none;
            border-radius: 16px;
            background: #007bff;

            cursor: pointer;

            &:hover {
                background-color: darken(#007bff, 10%);
            }

            @include media-breakpoint-up(md) {
                max-width: 220px;
            }
        }
    }
}
