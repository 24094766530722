.header {
    padding: 10px 0 30px;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
        padding-top: 30px;
    }

    &__logo {
        height: 25px;
        vertical-align: middle;

        @include media-breakpoint-up(md) {
            height: 40px;
        }
    }

    &__city {
        display: block;
        font-size: 12px;
        line-height: 15px !important;
        color: #000;

        position: relative;

        & strong {
            text-decoration: underline;
        }

        &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 10px;
            height: 10px;
            background: url('../img/icons/arrow-right.svg') no-repeat 50% 50%;
            background-size: 10px 10px;
            transform: rotate(90deg);
            margin-left: 5px;
            margin-bottom: 2px;
        }
    }

    & .icons {
        width: 25px;
        height: 25px;
    }

    & .row {
        align-items: center;
        justify-content: space-between;
    }

    &__phone {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.03em;
        font-weight: 700;
    }

    &__login {
        width: 106px;
    }
}