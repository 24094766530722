h1,
.h1 {
    
}

h2,
.h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 25px;
    font-weight: 700;

    @include media-breakpoint-up(sm) {
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 50px;
    }
}

h3,
.h3 {
    font-family: 'Futura PT';
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 25px;

    & small {
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
    }

    @include media-breakpoint-up(md) {
        font-size: 36px;
        line-height: 40px;

        margin-bottom: 51px;

        & small {
            font-size: 24px;
            line-height: 40px;
            font-weight: 300;
        }
    }
    @include media-breakpoint-up(xl) {

    }
}

h4,
.h4 {
    font-family: 'Futura PT';
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 23px;
    }
    @include media-breakpoint-up(xl) {

    }
}

p {
    margin-bottom: 20px;
}
