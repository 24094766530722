.reviews {
    padding-top: 88px;

    background: url('../img/vector3.svg') no-repeat 0 60px;

    @include media-breakpoint-up(xl) {
        padding-top: 120px;
    }

    &__ratings {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 45px;

        @include media-breakpoint-up(xl) {
            margin-left: 39px;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 0 1 auto;

            font-size: 14px;
            line-height: 18px;

            & span {
                position: relative;

                display: block;
                text-align: center;

                min-width: 52px;
                margin-right: 10px;
                padding: 12px 8px;

                font-weight: 800;
                font-size: 20px;
                line-height: 24px;

                color: #000;
                border: 2px solid #ff575f;
                border-radius: 8px;
                background: #fff;

                &.border-green {
                    border-color: #1bd389;
                }
            }

            &:first-of-type {
                @include media-breakpoint-up(xl) {
                    margin-right: 27px;
                }
                & span {
                    &:after {
                        content: '';

                        position: absolute;
                        top: -2px;
                        left: 8px;

                        display: block;

                        width: 14px;
                        height: 2px;

                        background: inherit;
                    }
                }
            }
        }
    }

    &__list {
        margin-right: -20px;
        margin-left: -20px;

        & .owl-item {
            padding-right: 20px;
            padding-left: 20px;
        }

        &__item {
            position: relative;

            min-height: 354px;
            margin: 10px 0 45px;
            padding: 29px 25px 49px;

            font-size: 16px;
            line-height: 122%;

            color: rgba(13, 29, 74, .7);
            border-radius: 18px;
            background: #fff;
            box-shadow: 0 0 10px rgba(13, 29, 74, .15);

            & .text-blue {
                color: #007bff;
            }

            &__date {
                margin-bottom: 3px;

                font-size: 12px;
                line-height: 122%;

                color: rgba(13, 29, 74, .7);
            }

            &__author {
                margin-bottom: 17px;

                font-weight: 800;
                font-size: 18px;
                line-height: 122%;

                color: #0d1d4a;
            }

            &__number {
                margin-bottom: 17px;

                font-size: 16px;
                line-height: 19px;

                color: #a2db00;
            }

            & p {
                margin: 4px 0;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            &__rating {
                position: absolute;
                bottom: 29px;
                left: 25px;

                display: flex;
                & span {
                    display: block;

                    width: 21px;
                    height: 20px;
                    margin-right: 5px;

                    background: url('../img/star.svg') no-repeat 50% 50%;

                    &.current {
                        background: url('../img/star-current.svg') no-repeat 50% 50%;
                    }
                }
            }
        }
    }
}
